// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { NgxLoggerLevel } from 'ngx-logger';

/* eslint-disable @typescript-eslint/naming-convention */
export const environment = {
  production: false,
  isE2E: typeof window !== 'undefined' && window['Cypress'],
  firebase: {
    apiKey: 'AIzaSyC4bKjQ6rW5PABC0s1jv1VYeThcIdVAQBc',
    authDomain: 'comprarporwhatsapp-develop.firebaseapp.com',
    databaseURL: 'https://comprarporwhatsapp-develop.firebaseio.com',
    projectId: 'comprarporwhatsapp-develop',
    storageBucket: 'comprarporwhatsapp-develop.appspot.com',
    messagingSenderId: '57246915625',
    appId: '1:57246915625:web:272e7d060d873fff289038',
    measurementId: 'G-5B09ZNY1TL',
  },
  hereMaps: {
    apiKey: 'AQXE9YHkQjDkLyO94YXTL4eP7g-svIe99K1MwMPapWQ',
  },
  googleMaps: {
    apiKey: 'AIzaSyAc_55SFmBBN_nvyJjdD43EzhU3gWjhoVU',
  },
  boxful: {
    susbcriptionUrl: 'https://pedixwppsocios.boxful.io/subscription_signups?base_plan=2945',
    profileUrl: 'https://pedixwppsocios.boxful.io/membership/subscriptions',
  },
  cdnUrl: null,
  debugAnalytics: true,
  siteUrl: 'https://pedix.dev',
  menuUrl: 'https://menu.pedix.dev',
  adminUrl: 'https://admin.pedix.dev',
  emailDomain: 'pedixwpp.com',
  supportEmail: 'soporte@pedixapp.com',
  accountancyEmail: 'soporte@pedixapp.com',
  landingUrl: 'https://info.pedix.dev/',
  helpCenterUrl: 'https://pedix.dev/centro-ayuda',
  youtubeUrl: 'https://pedix.dev/youtube',
  instagramUrl: 'https://pedix.dev/instagram',
  helpLinks: {
    stripeUpdatePayment:
      'https://www.notion.so/pedix/Suscripci-n-927e3830026d4c33968172ccd75acb2a#eef90e4383fc4c0d9c12e9a66240c876',
    zenriseUpdatePayment:
      'https://www.notion.so/pedix/Suscripci-n-927e3830026d4c33968172ccd75acb2a#bf79512e396740b683c1d5cde876d099',
  },
  salesAdvisorPhone: '543517337251',
  stripePublishableKey:
    'pk_test_51HD4yCIhPiaULCWq8ibNzMhYOrhwZdTJEXQKV8Vx00SPGwwjLYMFULsV8CvZfMye1ZoZY1fJVRE5wcy0tZHjQFnF00rNqttEA3',
  priceOptions: {
    AR: {
      priceId: 'price_1HJKhfIhPiaULCWq6O7g8SqU',
      price: '$800',
      labelledPrice: 'ochocientos pesos argentinos',
    },
    MX: {
      priceId: 'price_1HJKhHIhPiaULCWqZxIJhmLV',
      price: '$330',
      labelledPrice: 'tresciento treinta pesos mexicanos',
    },
    DEFAULT: {
      priceId: 'price_1HD5AEIhPiaULCWqkMoKWgQA',
      price: '$15',
      labelledPrice: 'quince dólares estadounidenses',
    },
  },
  logger: {
    logLevel: NgxLoggerLevel.LOG,
    serverLogLevel: NgxLoggerLevel.OFF,
    disableConsoleLogging: false,
  },
  apiUrl: 'https://southamerica-east1-comprarporwhatsapp-develop.cloudfunctions.net/api',
  apiV1Url: 'https://api.pedix.dev/v1',
  workerServicesUrl: 'https://services.pedix.dev',
  pedixPixelId: '2449935015217527',
  _v: 3, // TODO: remove
  // Food Bank Donation
  foodBankDonationAmount: 750,
};
